<template>
    <div class="profile_notes">
        <div class="title">
            <h3>Forms</h3>
            <div class="right_side">
                <div class="search_panel mr-3">
                    <div class="form-field">
                        <input type="text" name="search" placeholder="Search...">
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <PageItem/>
                <button :class="deleteBox.length ? 'delete active' : 'delete'"><i class="fas fa-trash-alt"></i></button>
            </div>
        </div>            
        <ul class="card_listing">
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="form1" class="checkbox">
                        <input type="checkbox" id="form1" value="form1" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>Newsletter Sign Up</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus.</p>
                    <label :for="showitem1" class="switch_option capsule_btn pt-0">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem1" hidden>
                        <div><span></span></div>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status delivered">Submitted</span>
                        </div>
                        <div class="col-item">
                            <h5>Created on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="form2" class="checkbox">
                        <input type="checkbox" id="form2" value="form2" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>Test Newsletter</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus.</p>
                    <label :for="showitem2" class="switch_option capsule_btn pt-0">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem2" hidden>
                        <div><span></span></div>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status not_delivered">Not Submitted</span>
                        </div>
                        <div class="col-item">
                            <h5>Created on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="form3" class="checkbox">
                        <input type="checkbox" id="form3" value="form3" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>Test Newsletter</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus.</p>
                    <label :for="showitem2" class="switch_option capsule_btn pt-0">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem2" hidden>
                        <div><span></span></div>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status not_delivered">Not Submitted</span>
                        </div>
                        <div class="col-item">
                            <h5>Created on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="form4" class="checkbox">
                        <input type="checkbox" id="form4" value="form4" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>Test Newsletter</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus.</p>
                    <label :for="showitem2" class="switch_option capsule_btn pt-0">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem2" hidden>
                        <div><span></span></div>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status not_delivered">Not Submitted</span>
                        </div>
                        <div class="col-item">
                            <h5>Created on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="form5" class="checkbox">
                        <input type="checkbox" id="form5" value="form5" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>Test Newsletter</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus.</p>
                    <label :for="showitem2" class="switch_option capsule_btn pt-0">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem2" hidden>
                        <div><span></span></div>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status not_delivered">Not Submitted</span>
                        </div>
                        <div class="col-item">
                            <h5>Created on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="viewFunc">
                    <label for="form6" class="checkbox">
                        <input type="checkbox" id="form6" value="form6" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <h4>Test Newsletter</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus.</p>
                    <label :for="showitem2" class="switch_option capsule_btn pt-0">
                        <h5>Show in Portal<span>Phasellus nec eros nec leo pharetra volutpat.</span></h5>
                        <input type="checkbox" :id="showitem2" hidden>
                        <div><span></span></div>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status not_delivered">Not Submitted</span>
                        </div>
                        <div class="col-item">
                            <h5>Created on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
        </ul>
        <div v-if="viewForm" class="edit_modal">
            <div class="edit_section p-0">
                <div class="form_body">
                    <h2 class="title_header">Newsletter Sign Up <button class="close_btn" @click="viewForm = false"><i class="fas fa-times"></i></button></h2>
                    <div class="form_content">
                        <h5>Email Address</h5>
                        <p>sumit_test@onboardme.io</p>
                        <h5>Content</h5>
                        <p>Pellentesque dapibus, nisi non faucibus vulputate, diam diam volutpat eros, sit amet congue erat ipsum eget lacus. Etiam id purus sed orci porta viverra.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pagination mt-2">
            <ul>
                <li>1</li>
                <li class="active">2</li>
                <li>3</li>
                <li>4</li>
                <li><i class="fas fa-ellipsis-h"></i></li>
            </ul>
        </div>
</template>

<script>
import PageItem from './PageItem.vue';
import NotesCard from './NotesCard.vue';
export default{
    name: 'ProfileForm',
    components:{
        PageItem,
        NotesCard
    },
    data(){
        return{
            viewForm: false,
            deleteBox:[],
            actionObj: [
                {
                    icon: "fa-search-plus",
                    label: "View",
                },
                {
                    icon: "fa-download",
                    label: "Download",
                }
            ]
        }
    },
    methods:{
        viewFunc(){
            this.viewForm = true;
        }
    }
}
</script>
